import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, ListItemButton as Item, Box, Typography, IconButton, SvgIcon, List, ListItemIcon, Avatar, ListItemText, Button } from '@mui/material';
import SmsIcon from '../../assets/svgs/productIcons/sms';
import StarLineIcon from '../../assets/svgs/icons/star-line';
import MailIcon from '../../assets/svgs/productIcons/mail';
import Upload2LineIcon from '../../assets/svgs/icons/upload-2-line';
import ListCheckLineIcon from '../../assets/svgs/icons/list-check-line';
import FunctionLineIcon from '../../assets/svgs/icons/function-line';
import { Stack, width } from '@mui/system';
import { configFile } from '../../config';

const { imagesUrl } = configFile;

const CardsMainSection = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // flexDirection: 'row',
    // gap: '14px',
    // padding: '62px 12px 12px 72px',
    // height: window.innerHeight/* '758px' */,
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //     padding: '70px 14px 14px 14px',
    //     height: 'auto',
    // },
    height: '377px',
    width: '100%'
}));

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))

const DriveSection = () => {
    return (
        <Grid container spacing={2} sx={{
            minHeight: '377px',
            height: '377px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }}>
            <Grid item xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '56px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center'
                        }}>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    color: 'grey.800',
                                    mr: '12px'
                                }}>
                                Recents
                            </Typography>

                            {/* <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: '16px',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Recents
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track your recent emails
                                </Typography>
                            </Box> */}

                            <Button
                                variant='outlined'
                                sx={{
                                    color: 'grey.0',
                                    backgroundColor: 'grey.800',
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                            >
                                All
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    color: 'grey.500',
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                            >
                                Document
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    color: 'grey.500',
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                            >
                                Spreadsheet
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    color: 'grey.500',
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                            >
                                Presentation
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    color: 'grey.500',
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                            >
                                PDF
                            </Button>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center' }}>
                            <Button
                                sx={{
                                    color: 'grey.600',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                }}
                                startIcon={
                                    <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                        <Upload2LineIcon color={'currentColor'} />
                                    </SvgIcon>
                                }
                            >
                                Upload
                            </Button>

                            <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                <ListCheckLineIcon color={'currentColor'} />
                            </SvgIcon>
                            <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                <FunctionLineIcon color={'currentColor'} />
                            </SvgIcon>
                        </Box>


                    </Box>

                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 56px)', width: '100%' }}>
                        <img src={imagesUrl?.diveNoData} alt='No more data' style={{ width: '40px', height: '40px' }} />
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: 600,
                                color: 'grey.700'
                            }}>
                            No recent items
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: 'grey.500'
                            }}>
                            Create something new or upload an item
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}

export default DriveSection