import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, ListItemButton as Item, Box, Typography, IconButton, SvgIcon, List, ListItemIcon, Avatar, ListItemText } from '@mui/material';
import SmsIcon from '../../assets/svgs/productIcons/sms';
import StarLineIcon from '../../assets/svgs/icons/star-line';
import MailIcon from '../../assets/svgs/productIcons/mail';
import { formatDate } from '../../utils/common';
import Scrollbar from '../../components/scrollbar'
import { formatDistanceToNowStrict } from 'date-fns';

const CardsMainSection = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // flexDirection: 'row',
    // gap: '14px',
    // padding: '62px 12px 12px 72px',
    // height: window.innerHeight/* '758px' */,
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //     padding: '70px 14px 14px 14px',
    //     height: 'auto',
    // },
    height: '377px',
    width: '100%'
}));

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))

const MailNChatSection = ({ chats, mails }) => {

    const { byId = {}, ids = [] } = mails;

    return (
        <Grid container spacing={2} sx={{
            minHeight: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
            height: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
            paddingLeft: '0px',
            paddingRight: '0px'
        }}>
            <Grid item sm={12} md={8} xl={8} xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon sx={{
                                width: '25px',
                                height: '28px',
                                color: 'primary.dark'
                            }}>
                                <MailIcon color={'currentColor'} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: '16px',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Email
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track your recent emails
                                </Typography>
                            </Box>

                        </Box>

                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: 'primary.dark'
                            }}>
                            View all
                        </Typography>

                    </Box>

                    {/* <List sx={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        borderTop: '1px solid',
                        borderColor: 'grey.300'
                    }}> */}
                    <Scrollbar sx={{ height: 300, }}>

                        {
                            ids.map((key) => (
                                <ListItem key={key} sx={{ maxHeight: '40px' }}>
                                    <ListItemIcon sx={{ minWidth: '60px' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '12px',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>

                                            <Avatar
                                                src='./dom'
                                                sx={{
                                                    width: '22px',
                                                    height: '22px'
                                                }} >
                                                {byId[key]?.from?.name[0]?.toUpperCase() || byId[key]?.from?.address[0]?.toUpperCase() || "U"}

                                            </Avatar>

                                            <SvgIcon sx={{
                                                width: '14px',
                                                height: "14px"
                                            }}>
                                                <StarLineIcon color={'currentColor'} />
                                            </SvgIcon>
                                        </Box>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '12px',
                                                // justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: 'grey.1000',
                                                        minWidth: '140px'
                                                    }}>
                                                    {byId[key]?.cc?.value?.length ? ((byId[key]?.from?.name?.split(" ")[0] || byId[key]?.from?.address?.split("@")[0])) + " " + byId[key]?.value.map(item => (item?.name?.split(" ")[0] || item?.address?.split("@")[0])) : (byId[key]?.from?.name || byId[key]?.from?.address?.split("@")[0])}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: 'grey.1000',
                                                        minWidth: '140px'
                                                    }}>
                                                    {byId[key]?.subject || "(no subject)"}
                                                    <Box component={'spam'} sx={{
                                                        pl: '5px',
                                                        color: 'grey.600'
                                                    }}>
                                                        <Typography sx={{
                                                            // color: (mail?.attributes?.flags.includes(SEEN)) ? 'grey.1100' : 'text.primary',
                                                            fontSize: '14px',
                                                            fontWeight: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 300 : 400,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            // width: '100%',
                                                            width: "700px"
                                                        }}>
                                                            {"-"} {byId[key]?.text}
                                                        </Typography>
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                    <Box sx={{
                                        display: 'flex',
                                        paddingRight: '12px',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: 'grey.600'
                                            }}>
                                            {byId[key]?.date && formatDistanceToNowStrict(new Date(byId[key]?.date), {
                                                addSuffix: false,
                                            })}
                                        </Typography>

                                    </Box>
                                </ListItem>

                            ))
                        }

                        {/* </List> */}
                    </Scrollbar>
                </Box>
            </Grid>

            <Grid item sm={12} md={4} xl={4} xs={12}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon sx={{
                                width: '25px',
                                height: '28px'
                            }}>
                                <SmsIcon />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: '16px',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Chat
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Recent messages
                                </Typography>
                            </Box>

                        </Box>

                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: 'primary.dark'
                            }}>
                            View all
                        </Typography>

                    </Box>
                    <Scrollbar sx={{ height: 300, }}>
                        {/* <List sx={{
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            borderTop: '1px solid',
                            borderColor: 'grey.300',
                            // height: 'calc(377px - 64px)',
                            // overflow: 'hidden',
                            // overflowY: 'scroll'
                        }}> */}
                        {
                            chats.map(({ lastMessage, unreadCount, user }, index) => (
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                        <Avatar
                                            src={user?.picture || ""}
                                            sx={{
                                                width: '26px',
                                                height: '26px',
                                                backgroundColor: 'primary.main',
                                                color: 'primary.contrastText'
                                            }}>
                                            {user?.name ? user.name[0].toUpperCase() : ''}
                                        </Avatar>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: 'grey.800'
                                                }}>
                                                {user?.name}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 600,
                                                    color: unreadCount === 0 ? 'grey.600' : 'grey.1100'

                                                }}>
                                                {lastMessage[0]?.message?.length > 15 ? `${lastMessage[0]?.message?.substring(0, 15)}...` : lastMessage[0]?.message || ""}
                                            </Typography>
                                        }
                                    />
                                    <Box sx={{
                                        display: 'flex',
                                        paddingRight: '12px',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: 'grey.600'
                                            }}>
                                            {formatDate(lastMessage[0]?.createdAt) || ""}
                                        </Typography>

                                        {unreadCount > 0 && < Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '16px',
                                            height: '16px',
                                            borderRadius: '50%',
                                            backgroundColor: 'success.300'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontWeight: 500,
                                                    color: 'success.dark'
                                                }}>
                                                {unreadCount !== 0 ? unreadCount : ""}

                                            </Typography>
                                        </Box>}
                                    </Box>
                                </ListItem>
                            ))
                        }
                        {/* </List> */}
                    </Scrollbar>

                </Box>
                {/* <Item>xs=4</Item> */}
            </Grid>
        </Grid >
    )
}

export default MailNChatSection