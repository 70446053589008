import { Icon } from "@iconify/react";
import Slider from "react-slick";
import {
  Typography,
  Box,
  useTheme,
  styled,
  Card,
  CardContent,
  List,
  ListItemButton,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Paper,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../lib/cookies";
import { setLocalStorageItem } from "../../lib/localStorage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./planStyle.css";
import Iconify from "../../components/iconify";
import Logger from "../../lib/Logger";
import { axiosGetCall } from "../../services/apisCall";
import { LoadingButton } from "@mui/lab";
import CustomModal from "../../components/modal/customModal";
import MessageBanner from "../../layouts/Banners/MessageBanner";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const logger = new Logger("listpublicplans");

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "34px",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  // padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: theme.palette.card.main,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette.grey[300]
}));

const ProductSlider = styled(Slider)(({ theme }) => ({
  "& .slick-slide": {
    padding: theme.spacing(2),
  },
  "& .slick-dots": {
    bottom: theme.spacing(-2),
  },
}));


const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ListPublicPlans = () => {
  const theme = useTheme();
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentSelectedPlans, setCurrentSelectedPlans] = useState({});

  const handleSelectPlan = (plan, product) => {
    if (selectedPlans.some((p) => p.plan_id === plan.plan_id)) {
      setSelectedPlans((prevSelected) => {
        if (prevSelected.some((p) => p.plan_id === plan.plan_id)) {
          // If already selected, remove it
          return prevSelected.filter((p) => p.plan_id !== plan.plan_id);
        }
      })
      return;
    }
    setCurrentSelectedPlans({
      ...plan,
      productName: product?.productName,
      productId: product?.productId,
      actualPrice: Number((plan?.max_user * plan?.price * (1 - (plan?.annual_discount / 100)) * 12).toFixed(2)),
      priceMonthly: (plan?.price).toFixed(2),
      priceAnnually: (plan?.price * Number((1 - (plan?.annual_discount / 100)).toFixed(2)) * 12).toFixed(2),
      users: plan?.max_user,
      subsType: "annual",
    })
    setOpen(true);
  };

  const formatPriceToRupees = (number) => {
    return number.toLocaleString('en-IN', {
      style: 'currency', currency: 'INR', minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }

  const priceCalculator = (user, price) => {
    // if (discount) {
    //   return user * price
    // }
    return Number((user * price).toFixed(2));
  }

  const handleIncrease = (e, productName, planId) => {
    setCurrentSelectedPlans((prev) => {
      const temp = { ...prev }

      const productDetails = temp;
      productDetails.users += 1;
      if (productDetails?.subsType === "monthly") {
        productDetails.actualPrice = priceCalculator(productDetails.users, productDetails?.priceMonthly);
      } else {
        productDetails.actualPrice = priceCalculator(productDetails.users, productDetails?.priceAnnually);
      }
      return temp;
    })
  };
  const handleDecrease = (e, productName, planId) => {

    setCurrentSelectedPlans((prev) => {
      const temp = { ...prev }
      const productDetails = temp;
      productDetails.users -= 1;
      if (productDetails?.subsType === "monthly") {
        productDetails.actualPrice = priceCalculator(productDetails.users, productDetails?.priceMonthly);
      } else {
        productDetails.actualPrice = priceCalculator(productDetails.users, productDetails?.priceAnnually);
      }
      return temp;
    })
  };
  const handleChange = (event, planId) => {
    handleProduct(event, planId, parseInt(event.target.value, 10))
  };

  const handleProduct = (e, planId, users, price) => {
    if (!users) {
      return;
    }
    // if (users > workSpaceMaxUser[planDetails?.plan]?.max) {
    setCurrentSelectedPlans((prev) => {
      const temp = { ...prev };
      const productDetails = temp;
      // const maxUsers = workSpaceMaxUser[planDetails?.plan]?.max;
      productDetails.users = users;
      if (productDetails?.subsType === "monthly") {
        productDetails.actualPrice = priceCalculator(users, productDetails?.priceMonthly, 0);
      } else {
        productDetails.actualPrice = priceCalculator(users, productDetails?.priceAnnually, 15);
      }
      return temp;
    })
    // }
  }

  const handleOnBlur = (event, productName, planId, maxUser, minUser) => {
    // handleProduct(event, productName, parseInt(event.target.value, 10), planDetails?.price)
    const users = parseInt(event.target.value, 10)
    if (!users) {
      return;
    }
    if (users > maxUser) {
      setCurrentSelectedPlans((prev) => {
        const temp = [...prev];
        const productDetails = temp.find(product => product?.plan_id === planId);
        productDetails.users = maxUser;
        if (productDetails?.subsType === "monthly") {
          productDetails.actualPrice = priceCalculator(maxUser, productDetails?.priceMonthly, 0);
        } else {
          productDetails.actualPrice = priceCalculator(maxUser, productDetails?.priceAnnually, 15);
        }
        return temp;
      })
    }
  }

  const handleSubscriptionType = (event, value, planId) => {
    setCurrentSelectedPlans((prev) => {
      const temp = { ...prev };
      const productDetails = temp;
      productDetails.actualPrice = (value === "monthly") ? priceCalculator(productDetails?.users, productDetails?.priceMonthly) : priceCalculator(productDetails?.users, productDetails?.priceAnnually);

      productDetails.subsType = value;
      return temp;
    })
    // setSubscriptionType(value);
  }

  const FeaturesList = ({ name }) => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
      // justifyContent: 'center',
      mt: '10px'
    }}>
      <Icon icon={"gravity-ui:check"} color={theme.palette.primary.main} />
      <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
        {name}
      </Typography>
    </Box>
  );

  const handleAddProduct = () => {
    setSelectedPlans((prev) => {
      if (currentSelectedPlans) {
        const temp = [...prev, currentSelectedPlans];
        return temp;
      }
      return prev;
    }
    )
    setOpen(false);
    setCurrentSelectedPlans(null);
  }



  const description = (currentPlans) => {
    // return "";
    if (!currentPlans) {
      return '';
    }

    const { plan_name: name, plan_id: planId, priceMonthly, users, priceAnnually, subsType, min_user: minUser, max_user: maxUser, actualPrice, annual_discount: annualDiscount } = currentPlans;
    return (
      <Box sx={{
        mt: 1.3,
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        width: '100%',
        // padding: '20px'
      }}>
        <Paper sx={{ width: '100%', borderRadius: '6px', backgroundColor: 'card.main' }}>
          <Stack direction={"column"} gap={1}>
            <Typography variant='h6'>Select the number of licences</Typography>
            {/* <Typography variant='body1'>The minimum amount is {minUser} licence.</Typography> */}
            {!(users > maxUser) ? <></>
              : <MessageBanner type={"success"} closeButton={false} icon={<Iconify icon={"emojione-v1:warning"} />} message={`The maximum amount is ${maxUser}`} successColor={"#fef7d9"} />}
            <TextField
              type="number"
              width={"100px"}
              value={users}
              size='small'
              onChange={(e) => handleChange(e, planId)}
              onBlur={(e) => handleOnBlur(e, name, planId, maxUser, minUser)}
              sx={{ width: '150px', '& input': { textAlign: 'center' } }}
              InputProps={{
                min: 1,
                max: 3,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={(e) => handleDecrease(e, name, planId)} size="small" disabled={users <= minUser}>
                      <RemoveIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => handleIncrease(e, name, planId)} size="small" disabled={users >= maxUser}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography variant='subtitle1'>Select a subscription cycle</Typography>
            <Stack direction={"row"} gap={2} sx={{ mb: '15px' }}>
              <Stack direction={"row"} onClick={(e) => handleSubscriptionType(e, "monthly", planId)} sx={{
                direction: "row",
                border: subsType === "monthly" ? `2px solid` : "1px solid",
                borderColor: (theme) => subsType === "monthly" ? theme.palette.primary.main : "grey.300",
                borderRadius: "5px", justifyContent: "space-between", p: 1, "&:hover": { backgroundColor: "grey.300", cursor: "pointer" }
              }}>
                <Stack>
                  <Typography>Monthly</Typography>
                  <Stack direction={"row"}>
                    <Typography>{formatPriceToRupees(priceMonthly)}</Typography>
                    <Typography>/monthly/user</Typography>
                  </Stack>
                </Stack>
                {subsType === "monthly" && <IconButton disableRipple color="primary" sx={{ mt: "-30px" }}>
                  <Iconify icon="icon-park-solid:check-one" width={"16px"} />
                </IconButton>}
              </Stack>
              <Stack direction="row" onClick={(e) => handleSubscriptionType(e, "annual", planId)} sx={{
                border: subsType === "annual" ? "2px solid" : "1px solid",
                borderColor: (theme) => subsType === "annual" ? theme.palette.primary.main : "grey.300",
                borderRadius: "5px", p: 1, justifyContent: "space-between", "&:hover": { backgroundColor: "grey.300", cursor: "pointer" }
              }}>
                <Stack>
                  <Stack direction={"row"} gap={1}>
                    <Typography>Annual</Typography>
                    <Box>
                      <Typography>{`Save ${annualDiscount}%`}</Typography>
                    </Box>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Typography>{formatPriceToRupees(priceAnnually)}</Typography>
                    <Typography>/year/user</Typography>
                  </Stack>
                </Stack>
                {subsType === "annual" && <IconButton disableRipple color="primary" sx={{
                  mt: "-30px",
                }}>
                  <Iconify icon="icon-park-solid:check-one" width={"16px"} height={"16px"} />
                </IconButton>}
              </Stack>
            </Stack>
            <Button variant="contained" onClick={() => handleAddProduct()}>{`Add Product ₹${actualPrice}`}</Button>
          </Stack>

        </Paper>
      </Box>
    )
  }


  const handleNavigate = () => {
    if (!getItem("sessionToken")) {
      navigate("/register", { state: { origin: "plan" } });
      return;
    }
    const finalData = selectedPlans.map((plan) => {
      const discount = Number((1 - (plan?.annual_discount) / 100).toFixed(2));
      const yearlyPrice = Number((plan?.price * discount * 12).toFixed(2));
      return {
        name: plan.plan_name,
        productName: plan.productName,
        productId: plan.productId,
        planId: plan.plan_id,
        users: plan.users,
        priceMonthly: Number(plan.price.toFixed(2)),
        priceAnnually: yearlyPrice,
        subsType: plan?.subsType,
        actualPrice: Number((plan?.max_user * plan?.price * discount * 12).toFixed(2)),
        minUser: plan.min_user,
        maxUser: plan.max_user,
        discount: plan.annual_discount,
        edit: true,
      };
    });
    const state = {
      selectedPlans: finalData,
    };
    setLocalStorageItem("planDetails", finalData);
    navigate("/checkout?page=product", { state })
  }
  const fetchProductPlans = async () => {
    try {
      const { data = [] } = await axiosGetCall("planDetails");
      setProducts(data);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    fetchProductPlans();
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: "1200px", margin: "auto", padding: 2 }}>
      <Heading variant="h3">Our Plans</Heading>
      {products.map((product, index) => (
        <StyledCard key={index}>
          <CardContent>
            <Typography variant="h4" gutterBottom align="left">
              {product.product_name}
            </Typography>

            <List>
              <ProductSlider {...sliderSettings}>
                {product.plans.map((plan) => (
                  <ListItemButton
                    key={plan.plan_id}
                    onClick={() => handleSelectPlan(plan, { productName: product.product_name, productId: product.product_id, price: plan?.price, minUser: plan?.minUser, maxUser: plan?.maxUser })}
                    selected={selectedPlans ? selectedPlans.some((p) => p.plan_id === plan.plan_id) : false}
                    sx={{
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: theme.palette.background.default,
                      borderRadius: "12px",
                      border: "1px solid",
                      borderColor:  selectedPlans.some((p) => p.plan_id === plan.plan_id) ? theme.palette.success.lighter : theme.palette.grey[300],
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.action.selected,
                      },
                    }}
                  >
                    <Card sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      // maxWidth: '300px',
                      padding: '20px 10px 20px 10px'
                    }}>
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '6px',
                          alignItems: 'center'
                        }}>
                          <Typography sx={{ fontSize: 20, fontWeight: 500 }} color="text.primary" gutterBottom>
                            {plan?.plan_name}
                          </Typography>
                          {/* <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                            {`${plan?.min_user} - ${plan?.max_user} users`}
                          </Typography> */}
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          // gap: '6px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: '10px'
                        }}>
                          <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                            {`₹${plan?.price}`}
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                            <del> &#8377; 40.00* </del>
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                            per user / month
                          </Typography>
                        </Box>
                        <Box sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          pt: '20px',
                          pb: '20px'
                        }}>
                          <LoadingButton
                            variant='outlined'
                            // color=''
                            disabled
                          >
                            Activated
                          </LoadingButton>
                        </Box>
                        <FeaturesList name={"Unlimited Meetings"} />
                        <FeaturesList name={"Unlimited Webinars"} />
                        <FeaturesList name={"Unlimited Call Features"} />
                        <FeaturesList name={"Unlimited Chats"} />
                        <FeaturesList name={"Unlimited Advanced Features"} />
                        <FeaturesList name={"24/7 Support"} />
                      </CardContent>
                    </Card>
                    {selectedPlans.some((p) => p.plan_id === plan.plan_id) && (
                      <Icon
                        icon="mdi:check-circle"
                        color={theme.palette.success.main}
                        style={{ position: 'absolute', bottom: '10px', left: '10px' }}
                      />
                    )}
                  </ListItemButton>))}
              </ProductSlider>
            </List>
          </CardContent>
        </StyledCard>
      ))
      }
      {
        selectedPlans.length > 0 && <AppBar
          position="fixed"
          color="primary"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {`Total ${selectedPlans.length} plans added`}
            </Typography>
            <Stack direction="row" alignItems={"center"} gap={1} onClick={() => handleNavigate()}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                }
              }}>
              <Typography variant="h6"> Proceed To Checkout</Typography>
              <Iconify icon="tabler:arrow-right" />
            </Stack>
          </Toolbar>
        </AppBar>
      }
      {open && <CustomModal
        open={open}
        title={"Plan Details"}
        description={description(currentSelectedPlans)}
        close={() => setOpen(false)}
      />}
    </Box >
  );
};

export default ListPublicPlans;
