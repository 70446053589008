// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";

// import HeadingTabPanel from '../sections/settings/headingTabPanel';
import UserFilters from "../sections/users/filters";
import ListTable from "../sections/users/listTable";
import { useEffect, useState } from "react";
import { axiosGetCall } from "../services/apisCall";
import { renderErrorMessage } from "../lib/toastMessage";
import { checkPermission } from "../utils/common";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const MainSection = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  // gap: "14px",
  padding: '62px 12px 12px 72px',
  height: "100%",
  overflowY: "scroll",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: '62px 12px 12px 72px',
    height: "auto",
  },
}));

const Card = styled("div")(({ theme }) => ({
  height: "auto",
  maxHeight: "calc(100%)",
  display: "flex",
  flexDirection: "column",
  borderRadius: "6px",
  border: "1px solid",
  overflow: "hidden",
  backgroundColor: theme.palette.card.main,
  borderColor: theme.palette.grey[300],
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const HeadingSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "12px",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    flexWrap: "wrap",
  },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: "20px",
  fontWeight: 600,
}));

const TableSection = styled("div")(() => ({
  // height: 'calc(100% - 30px)'
  // padding: '0px 0px 0px 0px'
}));

const UsersList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [usersList, setUsersList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [filterLabels, setFilterLabels] = useState([]);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const [search, setSearch] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterData, setFilterData] = useState({
    email: "",
    mobile: "",
    userId: "",
    downlineType: "",
    status: null,
    type: null,
    permissions: null,
    from: null,
    to: null,
  });

  const fetchUsersList = async () => {
    try {
      setIsListLoading(true);

      const skip = rowsPerPage * page;

      const payload = { skip, limit: rowsPerPage, ...filterData };

      if (search) payload.search = search;

      const { count = 0, usersList = [] } =
        (await axiosGetCall("usersList", payload)) || {};

      setUsersList(usersList);
      setCount(count);
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setIsListLoading(false);
    }
  };

  const findForSearchQuery = (search) => {
    setSearch(search);
    if (search?.trim()) {
      setPage(0);
      setLoad(true);
      if (!filterLabels.includes("search"))
        setFilterLabels([{ key: "search", value: search }]);
      setIsFilterApplied(false);
      return;
    }
    const updatedLabels = filterLabels.filter((item) => item?.key !== "search");
    setFilterLabels([...updatedLabels]);
    setUsersList([]);
    setIsFilterApplied(false);
    setPage(0);
    setLoad(true);
  };

  useEffect(() => {
    if (load) {
      fetchUsersList();
      setLoad(false);
    }

    // eslint-disable-next-line
  }, [load]);

  const resetFilters = () => {
    // fetchUsersList()
    setFilterLabels([]);
    setSearch("");
    setFilterData({
      email: "",
      mobile: "",
      userId: "",
      downlineType: "",
      status: null,
      type: null,
      permissions: null,
      from: null,
      to: null,
    });
    setIsFilterApplied(false);
    setLoad(true);
  };

  const filterLabelMap = {
    email: "Email",
    mobile: "Mobile",
    userId: "UserId",
    status: "Status",
    type: "Type",
    permissions: "Permissions",
    from: "From",
    to: "To",
    downlineType: "DownlineType",
  };

  const generateLabelChipToolTip = (item) => {
    if (["From", "To"].includes(item?.key))
      return moment(item?.value).format("D MMM, YYYY");
    else return item?.value;
  };

  const apply = (data) => {
    setUsersList([]);
    setSearch("");
    setIsFilterApplied(true);

    const label = [];

    Object.keys(data).forEach((element) => {
      if (["permissions", "status", "type"].includes(element)) {
        data[element].length > 0 &&
          label.push({ key: filterLabelMap[element], value: data[element] });
      } else {
        data[element] &&
          label.push({ key: filterLabelMap[element], value: data[element] });
      }
    });
    setFilterLabels(label);

    const newData = JSON.parse(JSON.stringify(data));
    setFilterData(newData);
    setPage(0);
    setLoad(true);
  };

  const reset = () => {
    resetFilters();
  };

  useEffect(() => {
    fetchUsersList();

    const permission = checkPermission();
    if (permission !== "l4") return navigate("/");

    //eslint-disable-next-line
  }, []);
  return (
    <MainSection>
      <ToastContainer />

      <Card>
        <HeadingSection>
          {/* <HeadingIcon disableFocusRipple disableRipple disableTouchRipple>
            <Icon icon="flowbite:users-solid" width={22} />
          </HeadingIcon> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <HeadingTypography>Customers ({count})</HeadingTypography>

            {!!filterLabels.length && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "6px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {filterLabels.map((item) => (
                  <Tooltip title={generateLabelChipToolTip(item)}>
                    <Chip label={item?.key} variant="outlined" size="small" />
                  </Tooltip>
                ))}

                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => resetFilters()}
                >
                  Clear Filter
                </Button>
              </Box>
            )}
          </Box>

          <UserFilters
            findForSearchQuery={findForSearchQuery}
            fetchUsersList={fetchUsersList}
            apply={apply}
            type="user"
            reset={reset}
            search={search}
            isFilterApplied={isFilterApplied}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </HeadingSection>

        <TableSection>
          <ListTable
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setLoad={setLoad}
            usersList={usersList}
            count={count}
            isListLoading={isListLoading}
            fetchUsersList={fetchUsersList}
          />
        </TableSection>
      </Card>
    </MainSection>
  );
};

export default UsersList;
