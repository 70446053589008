import React from "react";
import { Box, Typography } from "@mui/material";

const CreateDomain = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "76vh",
      }}
    >
      <Typography
        sx={{
          color: "grey.100",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        Stand by for the big reveal – coming soon!
      </Typography>
    </Box>
  );
};

export default CreateDomain;
