import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useTheme,
  Badge,
  SvgIcon,
  Popover,
  ListItemButton,
  List,
  ListItemText,
  Typography,
  ListItemIcon,
  Avatar,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoBlue from "../../assets/svgs/logo";
import { Icon } from "@iconify/react";
import { checkPermission } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { axiosGetCall } from "../../services/apisCall";
import { updateWalletBalance } from "../../redux/action/walletActions";
import jwtDecode from "jwt-decode";
import { getItem } from "../../lib/cookies";
import HeadphoneLineIcon from "../../assets/svgs/icons/headphone-line";
import Notification3LineIcon from "../../assets/svgs/icons/notification-3-line";
import Settings5LineIcon from "../../assets/svgs/icons/settings-5-line";
import MoonLineIcon from "../../assets/svgs/icons/moon-line";
import { ColorModeContext } from "../../context/ThemeContext";
import ArrowDownSFillIcon from "../../assets/svgs/icons/fill/arrow-down-s-fill";
import SuitcaseLineIcon from "../../assets/svgs/icons/suitcase-line";
import GroupLineIcon from "../../assets/svgs/icons/group-line";
import UserLineIcon from "../../assets/svgs/icons/user-line";
import NewsLineIcon from "../../assets/svgs/icons/news-line";
import LogoutCircleLineIcon from "../../assets/svgs/icons/logout-circle-line";
import { setItem } from "../../lib/cookies";

const LogoSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "10px",
  width: "180px",
  height: "50px",
  marginLeft: "-38px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "20px",
  },
}));

const MenuIconSection = styled(IconButton)(({ theme }) => ({
  width: "32px",
  height: "32px",
  color: theme.palette.grey[0],
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function Navbar() {
  const { openSidebar = false, setOpenSidebar = () => "" } =
    useContext(SidebarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const permission = checkPermission();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.wallet.balance);
  const colorMode = useContext(ColorModeContext);
  const workspacePreSelected = getItem("workspace");
  const token = jwtDecode(getItem("token"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [workspace, setWorkspaceList] = React.useState([]);
  const [workspacesSlected, setWorkspaceSelected] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const openProfile = Boolean(anchorElProfile);
  const idProfile = openProfile ? "simple-popover-profile" : undefined;

  const fetchBalance = async () => {
    try {
      const { balance } = await axiosGetCall("fetchWalletBalance");
      dispatch(updateWalletBalance(parseFloat(balance)));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchworkspace = async () => {
    try {
      const apidata = await axiosGetCall("fetchWorkspace", {
        email: token?.email,
      });

      setWorkspaceList(apidata.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setWorkspaceSelected(event.target.value);
    setItem("workspace", event.target.value);
  };

  useEffect(() => {
    if (workspacePreSelected) {
      setWorkspaceSelected(workspacePreSelected);
    }
    fetchworkspace();
    // fetchBalance();
  }, []);

  const add = [
    // {
    //   title: "Plans & Pricing",
    //   active: location?.pathname === "/plans",
    //   path: "/plans",
    //   startIcon: <Icon icon="iconoir:wallet" width={22} />,
    // },
    {
      title: "",
      active: location?.pathname === "/branding",
      path: "/branding",
      startIcon: <Icon icon="hugeicons:book-edit" width={22} />,
    },
    {
      title: "",
      active:
        location?.pathname === "/employeeList" ||
        location?.pathname === "/createUser",
      path: "/employeeList",
      startIcon: <Icon icon="clarity:employee-line" width={22} />,
    },
    {
      title: "",
      active:
        location?.pathname === "/users" || location?.pathname === "/createUser",
      path: "/users",
      startIcon: <Icon icon="ph:users-light" width={22} />,
    },
  ];

  const defaultMenu = [
    {
      title: "",
      active: location?.pathname === "/settings",
      path: "/settings",
      startIcon: <SettingsOutlinedIcon />,
    },
  ];

  const navbar = permission === "l4" ? [...add, ...defaultMenu] : defaultMenu;
  // console.log("workspace", workspace);
  return (
    <Box
      sx={{
        pl: "60px",
        flexDirection: "flex-end",
        position: "fixed",
        width: "100%",
        // color: "#fff",
        zIndex: 3,
        // boxShadow: "59px 0.5px 3px 0px rgba(117, 117, 117, 0.10)",
        height: "50px",
        // backgroundColor: "grey.0",
      }}
    >
      <AppBar
        position="static"
        component="nav"
        sx={{
          // backgroundColor: "grey.0",
          boxShadow: "none",
          // alignItems: 'center',
          height: "50px",
          minHeight: "50px !important",
        }}
      >
        <Toolbar
          sx={{
            minHeight: "50px !important",
            backgroundColor: "grey.0",
            borderBottom: "1px solid",
            borderColor: "grey.300",
          }}
        >
          <MenuIconSection
            onClick={() =>
              openSidebar ? setOpenSidebar(false) : setOpenSidebar(true)
            }
          >
            {openSidebar ? (
              <CloseIcon
                // onClick={() => setOpenSidebar(false)}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              />
            ) : (
              <MenuIcon
                // onClick={() => setOpenSidebar(true)}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              />
            )}
          </MenuIconSection>

          <LogoSection>
            <LogoBlue width="auto" height={"28px"} />
          </LogoSection>

          <FormControl size="small" sx={{ width: "180px", ml: "10px" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={workspacesSlected}
              // label="Age"
              onChange={handleChange}
              size="small"
            >
              {workspace?.map((item) => (
                <MenuItem value={item?.name}>{item?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "20px",
            }}
          >
            {/* {navbar.map(({ title, active, path, icon, startIcon }) => (
              <Button
                onClick={() => navigate(path)}
                key={title}
                sx={{
                  color: active ? "grey.70" : "grey.500",
                  height: "34px",
                  "&:hover": {
                    color: active ? "grey.70" : "grey.300",
                    bgcolor: "transparent",
                  },
                }}
                endIcon={icon}
                startIcon={startIcon}
              >
                {title}
              </Button>
            ))} */}

            <Button
              aria-describedby={id}
              onClick={() => navigate("/branding")}
              sx={{
                color: "grey.600",
                fontSize: "14px",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Branding
            </Button>

            <Button
              aria-describedby={id}
              onClick={handleClick}
              sx={{
                color: "grey.600",
                fontSize: "14px",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              endIcon={
                <SvgIcon>
                  <ArrowDownSFillIcon color={"currentColor"} />
                </SvgIcon>
              }
            >
              Accounts
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List
                sx={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  border: "1px solid",
                  borderColor: "grey.300",
                  overflow: "hidden",
                  borderRadius: "10px",
                  backgroundColor: "card.main",
                }}
              >
                <ListItemButton
                  onClick={() => navigate("/employeeList")}
                  sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <SuitcaseLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "grey.600",
                        }}
                      >
                        Employees
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/users")}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <GroupLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "grey.600",
                        }}
                      >
                        Customers
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Popover>

            <Button
              // onClick={() => handleNavigate("/settings")}
              key={"settings"}
              variant="outlined"
              // color="secondary"
              sx={{
                color:
                  location.pathname === "/settings" ? "grey.700" : "grey.700",
                height: "34px",
                fontSize: "12px",
                fontWeight: 600,
                border: "1px solid",
                borderColor: "grey.700",
                "&:hover": {
                  color: "grey.700",
                  bgcolor: "transparent",
                  borderColor: "grey.700",
                },
              }}
              startIcon={
                <SvgIcon
                  sx={{
                    color: "grey.700",
                    width: "16px",
                    height: "16px",
                  }}
                >
                  <HeadphoneLineIcon color={"currentColor"} />
                </SvgIcon>
              }
            >
              Help Center
            </Button>

            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              variant="dot"
            >
              <IconButton
                sx={{ color: "grey.700" }}
                //  onClick={() => handleNavigate("/settings")}
              >
                <SvgIcon
                  sx={{
                    color: "grey.700",
                    width: "16px",
                    height: "16px",
                  }}
                >
                  <Notification3LineIcon color={"currentColor"} />
                </SvgIcon>
              </IconButton>
            </StyledBadge>

            <IconButton
              sx={{ color: "grey.700" }}
              onClick={() => navigate("/settings")}
            >
              <SvgIcon
                sx={{
                  color: "grey.700",
                  width: "16px",
                  height: "16px",
                }}
              >
                <Settings5LineIcon color={"currentColor"} />
              </SvgIcon>
            </IconButton>
            <IconButton
              sx={{ color: "grey.700" }}
              onClick={() => colorMode.toggleColorMode()}
            >
              <SvgIcon
                sx={{
                  color: "grey.700",
                  width: "16px",
                  height: "16px",
                }}
              >
                <MoonLineIcon color={"currentColor"} />
              </SvgIcon>
            </IconButton>

            <Button
              aria-describedby={idProfile}
              onClick={handleClickProfile}
              sx={{
                color: "grey.600",
                fontSize: "14px",
                fontWeight: 500,
                padding: "0px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              endIcon={
                <SvgIcon>
                  <ArrowDownSFillIcon color={"currentColor"} />
                </SvgIcon>
              }
            >
              <Avatar src="./test" sx={{ height: "24px", width: "24px" }} />
            </Button>

            <Popover
              id={idProfile}
              open={openProfile}
              anchorEl={anchorElProfile}
              onClose={handleCloseProfile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List
                sx={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  border: "1px solid",
                  borderColor: "grey.300",
                  overflow: "hidden",
                  borderRadius: "10px",
                  backgroundColor: "card.main",
                }}
              >
                <ListItemButton
                  sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                  onClick={() => navigate("/settings")}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    >
                      <UserLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "grey.600",
                        }}
                      >
                        My Account
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                  onClick={() => navigate("/settings?tab=1")}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    >
                      <NewsLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "grey.600",
                        }}
                      >
                        KYC
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon
                    sx={{ minWidth: "30px" }}
                    onClick={() => navigate("/logout")}
                  >
                    <SvgIcon
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    >
                      <LogoutCircleLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "grey.600",
                        }}
                      >
                        Logout
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </Popover>

            {/* <Button variant='outlined' sx={{
                            bgcolor: 'primary.main',
                            height: '34px',
                            color: "#fff",
                            "&:hover": {
                                bgcolor: 'primary.main',
                            }
                        }} startIcon={<TextsmsOutlinedIcon />}>
                            Create Campaign
                        </Button> */}
          </Box>

          {/* <Box sx={{
                        ml: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                        border: '0.5px solid',
                        borderColor: 'primary.main',
                        borderRadius: '6px',
                        padding: '4px 10px 4px 10px'
                    }}>
                        <Icon icon="ion:wallet" color={theme.palette.primary.main} />
                        <Typography sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: '18px',
                            fontWaight: 600,
                            color: "grey.200"
                        }}>
                            <Icon icon="healthicons:rupee" width={"20px"} height={"20px"} /> {balance}
                        </Typography>

                        <Icon icon={"zondicons:add-outline"} color={theme.palette.primary.main} onClick={() => navigate('/settings')} />

                    </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
