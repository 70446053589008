import React from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import AmSvg from '../../assets/svgs/amSvg';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
import commonMessage from '../../utils/commonMessage.json'
import NetworkError from '../../components/Error';

const CardsMainSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.card.main,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '24px 22px',
  width: 'calc(100% - 100% / 1.6 - 7px)',
  borderRadius: "6px",
  border: '1px solid',
  borderColor: theme.palette.grey[1000],

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ProfileImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
}));

const CardsLeftSection = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% / 1.8)',
  gap: '8px'
}));

const CardsRightSection = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'calc(100% - 100% / 1.8)',
  flexDirection: 'column',
  gap: '6px'
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[100],
  fontSize: '18px',
  fontWeight: 400,
}));

const ActionButtonSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px'
}));

const AmName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: 500,
}));

const ActionButtonDetailsSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px'
}));

const CustomIconBuuton = styled(IconButton)(({ theme }) => ({
  height: '24px',
  width: '24px',
  backgroundColor: theme.palette.grey[0],
  color: theme.palette.grey[1000],
  '&:hover': {
    backgroundColor: theme.palette.grey[0],
    color: theme.palette.grey[1000],
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[200],
  fontSize: '14px',
  fontWeight: 500,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: '95%'
}));

const ProfileSection = styled(IconButton)(() => ({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  overflow: 'hidden',
}));

const AccountManagerSection = ({ AMName = "", AMprofileUrl, countryCode = "", email = "", mobileNo = "", loader }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleMailClick = (e) => {
    window.location.href = `mailto:${email}`
  }

  const handleMobileClick = (e) => {
    if (isMobile) {
      window.location.href = `tel:${countryCode}${mobileNo}`
    }
  }

  return (
    <>
      {AMName &&
        <CardsMainSection >
          <CardsLeftSection>
            <Heading>
              Connect with your Account Manager
            </Heading>

            <ActionButtonSection>
              <ActionButtonDetailsSection onClick={() => handleMailClick()}>

                <CustomIconBuuton>
                  <MailIcon sx={{ width: '16px' }} />
                </CustomIconBuuton>

                <Title >
                  {email === "prk@sarv.com" ? "sales@sarv.com " : email}
                </Title>
              </ActionButtonDetailsSection>

              <ActionButtonDetailsSection onClick={() => handleMobileClick()}>

                <CustomIconBuuton>
                  <LocalPhoneIcon sx={{ width: '16px' }} />
                </CustomIconBuuton>

                <Title>
                  {email === "prk@sarv.com" ? "9111-9111-00" : `+${countryCode}${mobileNo}`}
                </Title>
              </ActionButtonDetailsSection>

            </ActionButtonSection>
          </CardsLeftSection>

          <CardsRightSection>
            {(AMprofileUrl && email !== "prk@sarv.com") ? <ProfileSection disableFocusRipple disableTouchRipple disableRipple>
              <ProfileImage src={AMprofileUrl} alt='profileImage' />
            </ProfileSection> : <AmSvg />}

            <AmName>
              {email === "prk@sarv.com" ? "sales" : AMName}
            </AmName>
          </CardsRightSection>

        </CardsMainSection>}

      {loader ? <CardsMainSection>
        <CardsLeftSection>

          <Heading>
            <CustomSkeleton variant={"text"} width={"80%"} height={"18px"} />
          </Heading>

          <ActionButtonSection>

            <ActionButtonDetailsSection>
              <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
            </ActionButtonDetailsSection>

            <ActionButtonDetailsSection>
              <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
            </ActionButtonDetailsSection>

          </ActionButtonSection>

        </CardsLeftSection>
        <CardsRightSection>
          <CustomSkeleton animation="wave" variant="circular" width={80} height={80} />
          <CustomSkeleton variant={"text"} width={"50%"} height={"18px"} />
        </CardsRightSection>

      </CardsMainSection> : !AMName && <CardsMainSection>
        <CardsLeftSection>
          <Heading>
            Want to connect with your
            Account Manager
          </Heading>
        </CardsLeftSection>
        <NetworkError message={commonMessage?.noManagerAssign} /></CardsMainSection>}</>
  )
}

export default AccountManagerSection;
