import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, ListItemButton as Item, Box, Typography, IconButton, SvgIcon, List, ListItemIcon, Avatar, ListItemText, Button } from '@mui/material';
import SmsIcon from '../../assets/svgs/productIcons/sms';
import StarLineIcon from '../../assets/svgs/icons/star-line';
import MailIcon from '../../assets/svgs/productIcons/mail';
import Upload2LineIcon from '../../assets/svgs/icons/upload-2-line';
import ListCheckLineIcon from '../../assets/svgs/icons/list-check-line';
import FunctionLineIcon from '../../assets/svgs/icons/function-line';
import { Stack, width } from '@mui/system';
import { configFile } from '../../config';
import CalendarEventFillIcon from '../../assets/svgs/icons/fill/calendar-event-fill';
import AddBoxFillIcon from '../../assets/svgs/icons/fill/add-box-fill';
import MeetIcon from '../../assets/svgs/productIcons/meet';
import WebinarIcon from '../../assets/svgs/productIcons/webinar';
import VoiceIcon from '../../assets/svgs/productIcons/voice';

const { imagesUrl } = configFile;

const CardsMainSection = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // flexDirection: 'row',
    // gap: '14px',
    // padding: '62px 12px 12px 72px',
    // height: window.innerHeight/* '758px' */,
    // overflowY: 'scroll',
    // [theme.breakpoints.down('sm')]: {
    //     padding: '70px 14px 14px 14px',
    //     height: 'auto',
    // },
    height: '377px',
    width: '100%'
}));

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))

const MeetNWebinar = () => {
    return (
        <Grid container spacing={2} sx={{
            minHeight: {
                xs: '786px',
                sm: '786px',
                md: '282px',
                lg: '282px',
                xl: '377px',
            },
            height: {
                xs: '786px',
                sm: '786px',
                md: '282px',
                lg: '282px',
                xl: '377px',
            },
            paddingLeft: '0px',
            paddingRight: '0px'
        }
        } >
            <Grid item sm={12} md={4} xl={4} xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: 'card.main',
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon
                                sx={{ height: '30px', width: '30px', color: 'primary.dark' }}>
                                <MeetIcon color={"currentColor"} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Meetings
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track and manage your next schedule
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: 'grey.500',
                                maxWidth: '360px',
                                textAlign: 'center'
                            }}>
                            No meetings scheduled yet. Organize your team's collaboration by scheduling the first meeting.
                        </Typography>
                        <Button
                            sx={{
                                fontSize: '12px',
                                color: 'primary.light',
                                fontWeight: 600,
                                backgroundColor: 'primary.200',
                                mt: '8px',
                                padding: '8px !important'
                            }}
                            startIcon={
                                <SvgIcon sx={{
                                    width: '16px',
                                    height: '16px',
                                    ml: '2px'
                                }}>
                                    <CalendarEventFillIcon color={"currentColor"} />
                                </SvgIcon>
                            }
                        >

                            Schedule meeting
                        </Button>
                    </Stack>
                </Box>
            </Grid>
            <Grid item sm={12} md={4} xl={4} xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: 'card.main',
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon
                                sx={{ height: '30px', width: '30px', color: 'primary.dark' }}>
                                <WebinarIcon color={"currentColor"} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Webinars
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track and manage your next schedule
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: 'grey.500',
                                maxWidth: '360px',
                                textAlign: 'center'
                            }}>
                            No webinars hosted yet. Engage your audience by setting up a new webinar for your users.
                        </Typography>
                        <Button
                            sx={{
                                fontSize: '12px',
                                color: 'primary.light',
                                fontWeight: 600,
                                backgroundColor: 'primary.200',
                                mt: '8px',
                                padding: '8px !important'
                            }}
                            startIcon={
                                <SvgIcon sx={{
                                    width: '16px',
                                    height: '16px',
                                    ml: '2px'
                                }}>
                                    <AddBoxFillIcon color={"currentColor"} />
                                </SvgIcon>
                            }
                        >

                            Create webinar
                        </Button>
                    </Stack>
                </Box>
            </Grid>

            <Grid item sm={12} md={4} xl={4} xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: 'card.main',
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon
                                sx={{ height: '30px', width: '30px', color: 'primary.dark' }}>
                                <VoiceIcon color={'currentColor'} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Voice Broadcasts
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track and manage your next schedule
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: 'grey.500',
                                maxWidth: '360px',
                                textAlign: 'center'
                            }}>
                            No voice broadcasts sent yet. Communicate effectively by creating your first voice broadcast.
                        </Typography>
                        <Button
                            sx={{
                                fontSize: '12px',
                                color: 'primary.light',
                                fontWeight: 600,
                                backgroundColor: 'primary.200',
                                mt: '8px',
                                padding: '8px !important'
                            }}
                            startIcon={
                                <SvgIcon sx={{
                                    width: '16px',
                                    height: '16px',
                                    ml: '2px'
                                }}>
                                    <AddBoxFillIcon color={"currentColor"} />
                                </SvgIcon>
                            }
                        >

                            Create voice broadcast
                        </Button>
                    </Stack>
                </Box>
            </Grid>
        </Grid >
    )
}

export default MeetNWebinar