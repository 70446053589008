import React, { useEffect, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  useTheme,
  Modal,
  Typography,
  IconButton,
  SvgIcon,
} from "@mui/material";
// import MuiDrawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";

// Icons
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import MicNoneIcon from "@mui/icons-material/MicNone";
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import SpinnerLogo from "../../assets/svgs/spinnerLogo";
import { ProfileMenu } from "../../sections/profileMenu";
import jwt from "jwt-decode";
import { getItem } from "../../lib/cookies";
import { checkProductAccess, handleRedirect } from "../../utils/common";
import { removeBulkItems } from "../../lib/cookies";
import { handleLogout } from "../../services/apisCall";
import Logger from '../../lib/Logger';

import { ColorModeContext } from "../../context/ThemeContext";
// import CloseIcon from '@mui/icons-material/Close';
// import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SidebarContext } from "../../context/SidebarContext";
import MoreMenu from "../../sections/moreMenu";
import DashboardActiveIcon from "../../assets/svgs/productIcons/dashboardActive";
import DashboardIcon from "../../assets/svgs/productIcons/dashboard";
import MeetActiveIcon from "../../assets/svgs/productIcons/meetActive";
import MeetIcon from "../../assets/svgs/productIcons/meet";
import AppLauncherIcon from "../../assets/svgs/productIcons/appLauncher";
import WebinarActiveIcon from "../../assets/svgs/productIcons/webinarActive";
import WebinarIcon from "../../assets/svgs/productIcons/webinar";
import VoiceActiveIcon from "../../assets/svgs/productIcons/voiceActive";
import VoiceIcon from "../../assets/svgs/productIcons/voice";
import SmsActiveIcon from "../../assets/svgs/productIcons/smsActive";
import SmsIcon from "../../assets/svgs/productIcons/sms";
import MailIcon from "../../assets/svgs/productIcons/mail";
import MailActiveIcon from "../../assets/svgs/productIcons/mailActive";
import ChatActiveIcon from "../../assets/svgs/productIcons/chatActive";
import ChatIcon from "../../assets/svgs/productIcons/chat";
import DriveActiveIcon from "../../assets/svgs/productIcons/driveActive";
import DriveIcon from "../../assets/svgs/productIcons/drive";
import Iconify from "../../components/iconify";

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1.4),
  position: "absolute",
  bottom: "0px",
  flexDirection: 'column',
  paddingBottom: '22px',
  gap: ' 20px',
  width: '100%',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

/* const MobileSidebarHandler = styled("div")(({ theme }) => ({
  position: 'fixed',
  bottom: '12px',
  right: '12px',
  width: '45px',
  height: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
  [theme.breakpoints.up('sm')]: {
    display: "none"
  },
})); */

const CustomModal = styled(Modal)(({ theme }) => ({
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    display: "none"
  },
}));

// Drawer style handler
/* const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7.5)} + 1px)`,
  borderRight: '1px solid',
  display: "flex",
})); */

const Sidebar = () => {
  const location = useLocation();
  const colorMode = useContext(ColorModeContext);
  const { palette: { mode = "light" }, breakpoints } = useTheme();
  const { openSidebar = false, setOpenSidebar = () => "" } = useContext(SidebarContext)

  const greaterThanMid = useMediaQuery(breakpoints.up("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState(null);
  const [/*tokenInfo*/, setTokenInfo] = useState({ name: "User", profileUrl: "" });


  const iconSx = { width: "20px", height: "20px", color: 'grey.700', };
  const logger = new Logger("Sidebar");
  // const [openSidebar, setOpenSidebar] = useState(false)

  const defaultList = [
    {
      title: "Overview",
      icon:
        true ? <SvgIcon sx={iconSx}>
          <DashboardActiveIcon />
        </SvgIcon> :
          <SvgIcon sx={iconSx}>
            <DashboardIcon />
          </SvgIcon>
      ,
      active: location?.pathname === "/" ? true : false,
      path: "/",
      disabled: false,
      keys: "Console"
    },
    {
      title: "Mail",
      icon: location?.pathname === "/mail" ? <SvgIcon sx={iconSx}>
        <MailActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <MailIcon color={"currentColor"} />
        </SvgIcon>,
      active: location?.pathname === "/mail" ? true : false,
      path: "email",
      disabled: false,
      keys: "Email"
    },
    {
      title: "Chat",
      icon: location?.pathname === "/chat" ? <SvgIcon sx={iconSx}>
        <ChatActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <ChatIcon />
        </SvgIcon>,
      active: location?.pathname === "/chat" ? true : false,
      path: "chat",
      disabled: false,
      keys: "Chat"
    },
    {
      title: "Meet",
      icon: location?.pathname === "/meet" ? <SvgIcon sx={iconSx}>
        <MeetActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <MeetIcon color={"currentColor"} />
        </SvgIcon>,
      active: location?.pathname === "/meet" ? true : false,
      path: "meet",
      disabled: false,
      keys: "Meet"
    },
    {
      title: "Webinar",
      icon: location?.pathname === "/webinar" ? <SvgIcon sx={iconSx}>
        <WebinarActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <WebinarIcon color={"currentColor"} />
        </SvgIcon>,
      active: location?.pathname === "/webinar" ? true : false,
      path: "webinar",
      disabled: false,
      keys: "Webinar"
    },
    {
      title: "SMS",
      icon: location?.pathname === "/sms" ? <SvgIcon sx={iconSx}>
        <SmsActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <SmsIcon />
        </SvgIcon>,
      active: location?.pathname === "/sms" ? true : false,
      path: "sms",
      disabled: false,
      keys: "Sms"
    },


    {
      title: "Broadcast",
      icon: location?.pathname === "/voice" ? <SvgIcon sx={iconSx}>
        <VoiceActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <VoiceIcon color={"currentColor"} />
        </SvgIcon>,
      active: location?.pathname === "/voice" ? true : false,
      path: "voice",
      disabled: false,
      keys: "Broadcast"
    },
    {
      title: "Drive",
      icon: location?.pathname === "/drive" ? <SvgIcon sx={iconSx}>
        <DriveActiveIcon />
      </SvgIcon> :
        <SvgIcon sx={iconSx}>
          <DriveIcon color={"currentColor"} />
        </SvgIcon>,
      active: location?.pathname === "/drive" ? true : false,
      path: "drive",
      disabled: false,
      keys: "Drive"
    },
    {
      title: "List",
      icon: location?.pathname === "/lists" ? <Iconify icon="material-symbols:list" sx={iconSx} /> :
        <Iconify icon="material-symbols:list" sx={iconSx} />,
      active: location?.pathname === "/lists" ? true : false,
      path: "lists",
      disabled: false,
      keys: "Lists"
    },
  ];

  const products = checkProductAccess() || [];

  const homeSideBar = defaultList.filter(item => products.includes(item?.keys));
  const moreMenuShow = products.filter(item => ["Lists"].includes(item))?.length;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMore = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const handleCloseMore = () => {
    setAnchorElMore(null);
  };

  const logout = async () => {
    try {
      await handleLogout();
      if (window.self === window.top) {
        removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }]);
        window.location.href = "/"
      }
    } catch (error) {
      logger.error("Sidebar.jsx [logout] catchError", error);
    }
  }

  useEffect(() => {
    setOpenSidebar(greaterThanMid);
    // eslint-disable-next-line
  }, [greaterThanMid])

  useEffect(() => {
    try {
      const { name = "User", profileUrl = "" } = jwt(getItem("token"));
      setTokenInfo({ name, profileUrl });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {openSidebar &&
        <Box className="sidebarNav" sx={{
          // backgroundColor: 'common.grayBg',
          width: '60px',
          position: 'fixed',
          left: '0',
          top: '0',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          textAlign: 'center',
          backgroundColor: "grey.0",
          // pt: '70px',
          zIndex: 2,
          borderRight: "1px solid",
          borderColor: 'grey.300'
        }}
        >
          <Link
            key={"app"}
            underline="none"
            sx={{
              borderLeft: "2px solid",
              borderColor: 'transparent',
              pt: '10px',
              pb: '10px'
            }}
          >
            <Box>
              <IconButton
                sx={{
                  color: "grey.300",
                  '&:hover': {
                    backgroundColor: 'transparent !important'
                  },
                  mb: '-3px'
                }}>
                <SvgIcon sx={iconSx}>
                  <AppLauncherIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Link>

          {/* Side bar list section. */}
          {homeSideBar.map(({ title, icon, active, path }, id) => (
            <Link
              key={id}
              onClick={() => handleRedirect(path, "", {}, true, mode)}
              underline="none"
              sx={{
                borderLeft: active ? "2px solid" : "none",
                borderColor: 'primary.light'
              }}
            >
              <Box>
                <IconButton
                  sx={{
                    // color: active ? 'rgb(63, 80, 181)' : "grey.300",
                    '&:hover': {
                      backgroundColor: 'transparent !important'
                    },
                    mb: '-3px'
                  }}>
                  {icon}
                </IconButton>
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    color: active ? "grey.1000" : 'grey.700'
                  }}
                >{title}</Typography>
              </Box>
            </Link>
          ))}

          {/* {!!moreMenuShow && <ListItem
            key={"More"}
            disablePadding
            sx={{
              display: "block",
              borderRadius: "10px",
            }}
            onClick={(e) => handleClickMore(e)}
            aria-controls={openMore ? "more-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMore ? "true" : undefined}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                flexDirection: "column",
                px: 1,
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  color: "grey.100",
                  backgroundColor: false ? activeBgColor : "transparent",
                  width: "44px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "15px",
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                {/* <MoreVertIcon sx={iconSx} /> */}
          {/* <SvgIcon sx={iconSx}>
                  <MoreIcon />
                </SvgIcon>

              </ListItemIcon>
              <ListItemText
                primary={"More"}
                primaryTypographyProps={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: "grey.0",
                }}
              />
            </ListItemButton>
          </ListItem>
          } */}

          {/* <DrawerFooter>
            <Box
              onClick={(e) => handleClick(e)}
              aria-controls={open ? "profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                alt={tokenInfo?.name}
                src={tokenInfo?.profileUrl || "/static/images/avatar/1.jpg"}
                sx={{ color: "#F0F0F0", bgcolor: '#2065D1', }}
              />
            </Box>
            {/* <SpinnerLogo /* width={"30"} height={"30"} 

          </DrawerFooter> */}

          <ProfileMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            logout={logout}
            handleTheme={() => colorMode.toggleColorMode()}
          />

          <MoreMenu
            anchorEl={anchorElMore}
            handleClose={handleCloseMore}
            self={true}
            products={products}
          // logout={logout}
          // handleTheme={() => colorMode.toggleColorMode()}
          />
          {/* </Drawer> */}
        </Box>
      }

      {/* <MobileSidebarHandler onClick={() => setOpenSidebar(!openSidebar)} >
        {openSidebar ?
          <IconButton
            sx={{
              width: '45px',
              height: '45px',
              backgroundColor: 'background.paper'
            }}
          >
            <CloseIcon />
          </IconButton>
          :
          <Logo />
        }


      </MobileSidebarHandler> */}


      <CustomModal
        open={openSidebar}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(20, 22, 24, 0.90)',
            },
          },
        }}
      >
        <></>
      </CustomModal>

    </>
  );
};

export default Sidebar;
