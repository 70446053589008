import React, { useState, useMemo, useEffect } from 'react';
import './assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from "react-router-dom";
import { Provider } from "react-redux";

//Theme
import ThemeProvider from './theme';

import AllRoutes from './lib/Routes';
import { ColorModeContext, TimeOutContext } from './context/ThemeContext';
import store from "./store";
import { setItem, getItem } from './lib/cookies';
import { SidebarContext } from './context/SidebarContext';

// import { currentProduct } from './config';
import { axiosGetCall } from './services/apisCall';
import { LOCAL_STORAGE } from './config/enums';
import { BrandingContext } from './context/brandingContext';
// import { useTheme } from '@emotion/react';
// import { LocalizationProvider } from './locales';
import { SettingsProvider } from './components/settings';
// const product = currentProduct();
// const { title } = await import(`./config/${product}/branding`)

function App() {
  const [mode, setMode] = useState(getItem("theme") || 'light');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [branding, setBranding] = useState({});

  //All routes
  const router = createBrowserRouter(createRoutesFromElements(AllRoutes()));

  // Added for future ref to toggle theme
  // handle theme change
  const colorMode = useMemo(() => ({
    toggleColorMode: () => {
      setItem("theme", mode === 'light' ? 'dark' : 'light');
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    },
  }), [mode]);

  const setContextTimeout = (scheduleTime) => {

    const currentTime = new Date();

    const otherTime = new Date(scheduleTime);

    const delay = otherTime - currentTime;

    if (delay < 0 && mode !== 'dark') {
      colorMode.toggleColorMode();
    }
    else {
      const timeoutId = setTimeout(() => {
        if (mode !== 'dark') {
          colorMode.toggleColorMode();
        }
      }, delay)

      return () => clearTimeout(timeoutId);
    }
  }

  const getBrandingFormBackend = async () => {
    const data = await axiosGetCall('fetchBranding', { domain: window.location.hostname });

    localStorage.setItem(LOCAL_STORAGE?.BRANDING, JSON.stringify({
      exp: + new Date() + 86400 * 1000,
      ...data
    }));

    return data;
  }

  const fetchBrandings = async () => {
    try {
      const cookiesBranding = localStorage.getItem(LOCAL_STORAGE?.BRANDING);
      let favicon = "";
      let newTitle = "Console";
      let data = {}

      if (cookiesBranding) {

        data = JSON.parse(cookiesBranding);

        if (+new Date() > data?.exp) {
          data = await getBrandingFormBackend()
        }
        const { faviconUrl, title, } = data;

        favicon = faviconUrl;
        if (title) newTitle = title
        setBranding(data)

      } else {
        data = await getBrandingFormBackend();
        const { faviconUrl, title, } = data || {}

        favicon = faviconUrl;
        if (title) newTitle = title;
        setBranding(data)

      }

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }

      link.href = favicon;
      document.title = newTitle;

      return data;

    } catch (error) {

    }
  }

  useEffect(() => {
    const scheduleTime = getItem("scheduleTime");
    if (scheduleTime) {
      setContextTimeout(scheduleTime)
    }
    setItem("theme", mode);
    fetchBrandings()

    // eslint-disable-next-line 
  }, [])

  return (
    Object.keys(branding)?.length ?
      // <LocalizationProvider>

      <SettingsProvider
        defaultSettings={{
          themeMode: mode, // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider mode={mode}>
          <Provider store={store}>
            <BrandingContext.Provider value={branding} >
              <ColorModeContext.Provider value={colorMode}>
                <SidebarContext.Provider value={{ openSidebar, setOpenSidebar }}>
                  <TimeOutContext.Provider value={{ setContextTimeout }}>
                    {/* <ThemeProvider mode={mode}> */}
                    <RouterProvider router={router} />
                    {/* </ThemeProvider> */}
                  </TimeOutContext.Provider>
                </SidebarContext.Provider>
              </ColorModeContext.Provider>
            </BrandingContext.Provider>
          </Provider>
        </ThemeProvider>
      </SettingsProvider>
      // </LocalizationProvider>
      :
      <></>
  );
}

export default App;
