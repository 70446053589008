import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Button, Typography } from '@mui/material';
import { Box, maxHeight, Stack } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Iconify from '../../components/iconify';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { updateWalletBalance } from '../../redux/action/walletActions';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import { displayRazorpay } from '../../lib/razorpay';
import Logger from '../../lib/Logger';
import CustomModal from '../../components/modal/customModal';
import { useDispatch } from 'react-redux';
import BillingAddress from './billingAddress';
import { useQueryParams } from '../../hooks/useQueryParams';
import OrderBreadCrumbs from './breadCrumbs';
import { ToastContainer } from 'react-toastify';
import { getLocalStorageItem } from '../../lib/localStorage';
import MessageBanner from '../../layouts/Banners/MessageBanner';
import Scrollbar from '../../components/scrollbar';
const MainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    padding: '48px 0px 0px 72px',
    height: '100%',
    overflow: 'hidden',
    // maxWidth: '1400px',
    [theme.breakpoints.down('sm')]: {
        padding: '70px 14px 14px 14px',
        height: 'auto',
    },
}));


const logger = new Logger("order");

const Checkout = () => {
    const location = useLocation();
    const [planDetails, setPlanDetails] = useState(getLocalStorageItem("planDetails"));
    const [value, setValue] = useState(planDetails?.user);
    const [step, setStep] = useState("product");
    const [payOnline, setPayOnline] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [payWallet, setPayWallet] = useState(false);
    const [currentStep, setCurrentStep] = useState("address");
    const [totalSubsType, setTotalSubsType] = useState({});
    const [selectedProducts, setSelectedProducts] = useState(getLocalStorageItem("planDetails") || [])

    const [billingAddress, setBillingAddress] = useState({});
    const [loader, setLoader] = useState(false);
    const query = useQueryParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchBalance = async () => {
        try {
            const { balance } = await axiosGetCall("fetchWalletBalance");
            dispatch(updateWalletBalance(parseFloat(balance)));
        } catch (error) {
            logger.error(error);
        }
    }

    const verifyPayment = async (data) => {
        try {
            await axiosPostCall("verifyPayment", data);
            const totalAmount = totalPrice();
            const totalAmountPay = totalAmount - currentBalance;
            const planDetails = selectedProducts.map(({ planId, productId, name, subsType, users, actualPrice }) => {
                return {
                    planId,
                    productId,
                    planName: name,
                    subsType,
                    price: actualPrice,
                    users,
                    recurring: false,
                }
            })

            setCheckout(true);
            const { message } = await axiosPostCall("assignPlan", { planDetails, wallet: payWallet, billingAddress, total: payWallet ? totalAmountPay : totalAmount });
            renderSuccessMessage(message);
            fetchBalance();
        } catch (error) {
            logger.error(error);
        }
    }

    const razorpayHandler = async () => {
        try {
            setCheckout(false);
            if (step === "product") {
                setStep("checkout");
                navigate(`?page=${"checkout"}`)
                return;
            }
            const totalAmount = totalPrice();
            const totalAmountPay = totalAmount - currentBalance;
            setLoader(true);
            if (payWallet && totalAmountPay > 0) {
                const { order: { id } } = await axiosGetCall("createOrder", { amount: totalAmountPay });
                await displayRazorpay({ order: { id } }, verifyPayment);
            } else if (payWallet && totalAmountPay <= 0) {
                const planDetails = selectedProducts.map(({ planId, productId, name, subsType, users, actualPrice }) => {
                    return {
                        planId,
                        productId,
                        planName: name,
                        subsType,
                        users,
                        price: actualPrice,
                        recurring: false,
                    }
                })
                const { message } = await axiosPostCall("assignPlan", { planDetails, wallet: true, billingAddress, total: totalAmount });
                setCheckout(true);
                renderSuccessMessage(message);
            } else {
                const { order: { id } } = await axiosGetCall("createOrder", { amount: totalAmount });
                await displayRazorpay({ order: { id } }, verifyPayment);
            }
            setLoader(false);
        } catch (error) {
            logger.error(error);
            return renderErrorMessage(error.message);
        }
    }

    const priceCaluclator = (user, price) => {
        return Number((user * price).toFixed(2));
    }

    const subscriptionMapper = {
        "monthly": "Monthly Subscription",
        "annual": "Annual Subscription",
    }
    const handleIncrease = (e, productName, planId) => {
        setValue((prev) => prev + 1);
        setSelectedProducts((prev) => {
            const temp = [...prev];
            const productDetails = temp.find(product => product?.planId === planId);
            productDetails.users += 1;
            if (productDetails?.subsType === "monthly") {
                productDetails.actualPrice = priceCaluclator(productDetails.users, productDetails?.priceMonthly);
            } else {
                productDetails.actualPrice = priceCaluclator(productDetails.users, productDetails?.priceAnnually);
            }
            return temp;
        })
    };
    const handleDecrease = (e, productName, planId) => {
        setValue(prev => prev - 1);
        setSelectedProducts((prev) => {
            const temp = [...prev];
            const productDetails = temp.find(product => product?.planId === planId);
            productDetails.users -= 1;
            if (productDetails?.subsType === "monthly") {
                productDetails.actualPrice = priceCaluclator(productDetails.users, productDetails?.priceMonthly);
            } else {
                productDetails.actualPrice = priceCaluclator(productDetails.users, productDetails?.priceAnnually);
            }
            return temp;
        })
    };
    const handleChange = (event, productName) => {
        setValue(parseInt(event.target.value, 10))
        handleProduct(event, productName, parseInt(event.target.value, 10), planDetails?.price)
        //     setSelectedProducts((prev) => {
        //     const temp = [...prev];
        //     const productDetails = temp.find(product => product?.name === productName)
        //     productDetails.users = parseInt(event.target.value, 10);
        //     return temp;
        // })
        // setValue(parseInt(event.target.value, 10));
    };
    const handlePayonline = (event) => {
        setPayOnline(event.target.value);
    }
    const handleSubscriptionType = (event, value, planId) => {
        setSelectedProducts((prev) => {
            const temp = [...prev];
            const productDetails = temp.find(product => product?.planId === planId);
            productDetails.actualPrice = (value === "monthly") ? priceCaluclator(productDetails?.users, productDetails?.priceMonthly) : priceCaluclator(productDetails?.users, productDetails?.priceAnnually);
            productDetails.subsType = value;
            return temp;
        })
        // setSubscriptionType(value);
    }

    const handleEdit = (planId) => {
        setSelectedProducts((prev) => {
            const temp = [...prev];
            const productDetails = temp.find(product => product?.planId === planId);
            productDetails.edit = !productDetails.edit;
            return temp;
        })
    }

    const formatPriceToRupees = (number) => {
        return number.toLocaleString('en-IN', {
            style: 'currency', currency: 'INR', minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    }

    const totalPrice = () => {
        let total = 0;
        selectedProducts.forEach(({ actualPrice }) => {
            console.log("🚀 ~ selectedProducts.forEach ~ actualPrice:", typeof actualPrice)
            total = total + actualPrice;
        })
        console.log("🚀 ~ totalPrice ~ total:", total)
        console.log("🚀 ~ totalPrice ~ total:", typeof total)
        return Number(total.toFixed(2));
    }

    const calculateTotalSubsType = () => {
        let totalMonthly = 0;
        let totalAnnual = 0;

        selectedProducts.forEach(product => {
            if (product.subsType === "monthly") {
                totalMonthly += product.actualPrice;
            } else if (product.subsType === "annual") {
                totalAnnual += product.actualPrice;
            }
        });

        return {
            totalMonthly: totalMonthly,
            totalAnnual: totalAnnual,
        };
    }


    const description = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '350px'
        }}> :
            {/* <video src='small.mp4' alt='success' loop/> */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="90px">
                <circle class="path circle" fill="none" stroke="#089b2a" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <polyline class="path check" fill="none" stroke="#089b2a" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Congratulations!
            </Typography>
            {/* <Typography sx={{ fontSize: '16px', textAlign: 'center', maxWidth: '270px' }}>
                <b>Pro</b>  plan for <b>{value}</b> licences is activated on your account.
            </Typography> */}
            <Button onClick={() => navigate("/")} variant='contained' sx={{ boxShadow: 'none', minWidth: '300px', mt: '10px' }}>
                Back to Home
            </Button>
        </Box>
    )

    const handleProduct = (e, productName, users, price) => {
        if (!users) {
            return;
        }
        // if (users > workSpaceMaxUser[planDetails?.plan]?.max) {
        setSelectedProducts((prev) => {
            const temp = [...prev];
            const productDetails = temp.find(product => product?.name === productName)
            // const maxUsers = workSpaceMaxUser[planDetails?.plan]?.max;
            productDetails.users = users;
            if (productDetails?.subsType === "monthly") {
                productDetails.actualPrice = priceCaluclator(users, productDetails?.priceMonthly);
            } else {
                productDetails.actualPrice = priceCaluclator(users, productDetails?.priceAnnuallyß);
            }
            return temp;
        })
        // }
    }

    const formatDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }

    const setPage = (newPage) => {
        navigate(`?page=${newPage}`);
    };

    const handleOnBlur = (event, productName, planId, maxUser, minUser) => {
        // handleProduct(event, productName, parseInt(event.target.value, 10), planDetails?.price)
        const users = parseInt(event.target.value, 10)
        if (!users) {
            return;
        }
        if (users > maxUser) {
            setSelectedProducts((prev) => {
                const temp = [...prev];
                const productDetails = temp.find(product => product?.planId === planId);
                productDetails.warning = true;
                productDetails.users = maxUser;
                if (productDetails?.subsType === "monthly") {
                    productDetails.actualPrice = priceCaluclator(maxUser, productDetails?.priceMonthly);
                } else {
                    productDetails.actualPrice = priceCaluclator(maxUser, productDetails?.priceAnnually);
                }
                return temp;
            })
        } else {
            setSelectedProducts((prev) => {
                const temp = [...prev];
                const productDetails = temp.find(product => product?.name === productName)
                // const maxUsers = workSpaceMaxUser[planDetails?.plan]?.max;
                productDetails.warning = false;
                return temp;
            })
        }
    }

    const fetchWalletBalance = async () => {
        try {
            const { balance = 0 } = await axiosGetCall("fetchWalletBalance");
            setCurrentBalance(balance);
        } catch (error) {
            logger.error(error)
        }
    }
    useEffect(() => {
        fetchWalletBalance();
    }, [])

    useEffect(() => {
        const total = calculateTotalSubsType();
        setTotalSubsType(total);
    }, [selectedProducts])

    useEffect(() => {
        if (!query.get('page')) {
            navigate(`?page=product`, { replace: true });
        } else {
            setStep(query.get('page'));
        }
    }, [navigate, query]);

    useEffect(() => {
        if (location?.state) {
            const { selectedPlans } = location?.state || {};
            setPlanDetails(selectedPlans);
        }
        // if(!location?.state) {
        //     const [user, price , plan] = getBulkLocalStorageItem(["user", "price", "plan"]);
        //     setPlanDetails({user, price, plan})
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        navigate("/");
        setCheckout(false);
    }

    return (
        <MainSection>
            <ToastContainer />
            <CustomModal
                open={checkout}
                title={"Checkout"}
                description={description}
                close={() => handleClose()}
            />
            <Grid container spacing={0} sx={{ mt: "-12px" }}>
                <Grid item xs={12} md={7}>
                    <Stack sx={{ pt: "20px", pr: "20px", pb: "70px", /* maxHeight: "calc(100vh - 60px)", overflow: "hidden", overflowY: "scroll" */ }} >
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
                            <Typography variant="h4">Your Product</Typography>
                            {/* <CustomBreadcrumbs links={[
                                { name: 'Your Products', to: "?page=product", handleRedirect: () => setPage("product"), active: false },
                                { name: 'Checkout', to: "?page=checkout", handleRedirect: () => setPage("checkout"), active: query.get('page') === 'checkout' },
                            ]}
                                separator={<NavigateNextIcon fontSize="small" />}
                            /> */}
                            <OrderBreadCrumbs links={[
                                { name: 'Products', to: "?page=product", handleRedirect: () => setPage("product"), active: query.get('page') === 'product' },
                                { name: 'Checkout', to: "?page=checkout", handleRedirect: () => setPage("checkout"), active: query.get('page') === 'checkout' },
                            ]}
                                separator={<NavigateNextIcon fontSize="small" />} />
                        </Stack>
                        {step === "product" && <Stack>
                            <Scrollbar sx={{ height: 'calc(100vh - 117px)', }}>

                                {selectedProducts.map(({ name, productId, planId, productName, priceMonthly, users, priceAnnually, subsType, minUser, maxUser, warning, edit, discount }) => (
                                    <Card sx={{ border: '1px solid', borderColor: 'grey.300', borderRadius: '6px !important', mb: '10px', }}>
                                        <Accordion
                                            expanded={edit}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                width={"100%"}
                                                sx={{ margin: '0' }}
                                            >
                                                <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Typography variant="h6">{`${productName} ${name}`}</Typography>
                                                    <Stack direction={"row"} gap={0.5} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => handleEdit(planId)}>
                                                        {/* <Typography variant="subtitle2" sx={{
                                                            color: (theme) => theme.palette.secondary?.main,
                                                            cursor: "pointer",
                                                            "&:hover": {
                                                                color: (theme) => theme.palette.secondary?.darker
                                                            }
                                                        }}>Edit</Typography> */}
                                                        <IconButton
                                                            disableRipple
                                                            sx={{
                                                                transition: 'transform 0.3s ease-in-out',
                                                                '&:hover': {
                                                                    transform: 'scale(1.2)',
                                                                },
                                                            }}
                                                        >
                                                            <Iconify icon="mingcute:down-line" />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Stack direction={"column"} gap={1}>
                                                    <Typography variant='h6'>Select the number of users</Typography>
                                                    {!(users > maxUser) ? <Typography variant='body1'>The minimum amount is {minUser} user.</Typography>
                                                        : <MessageBanner type={"success"} closeButton={false} icon={<Iconify icon={"emojione-v1:warning"} />} message={`The maximum amount is ${maxUser}`} successColor={"#fef7d9"} />}
                                                    <TextField
                                                        type="number"
                                                        width={"100px"}
                                                        value={users}
                                                        size='small'
                                                        onChange={(e) => handleChange(e, name)}
                                                        onBlur={(e) => handleOnBlur(e, name, planId, maxUser, minUser)}
                                                        sx={{ width: '150px', '& input': { textAlign: 'center' } }}
                                                        InputProps={{
                                                            min: 0,
                                                            max: 3,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconButton onClick={(e) => handleDecrease(e, name, planId)} size="small" disabled={users <= minUser}>
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={(e) => handleIncrease(e, name, planId)} size="small" disabled={users >= maxUser}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                    <Typography variant='subtitle1'>Select a subscription cycle</Typography>
                                                    <Stack direction={"row"} gap={2}>
                                                        <Stack direction={"row"} onClick={(e) => handleSubscriptionType(e, "monthly", planId)} sx={{
                                                            direction: "row",
                                                            border: subsType === "monthly" ? `2px solid` : "1px solid",
                                                            borderColor: (theme) => subsType === "monthly" ? theme.palette.primary.main : "grey.300",
                                                            borderRadius: "5px", justifyContent: "space-between", p: 1, "&:hover": { backgroundColor: "grey.300", cursor: "pointer" }
                                                        }}>
                                                            <Stack>
                                                                <Typography>Monthly</Typography>
                                                                <Stack direction={"row"}>
                                                                    <Typography>{formatPriceToRupees(priceMonthly)}</Typography>
                                                                    <Typography>/monthly/user</Typography>
                                                                </Stack>
                                                            </Stack>
                                                            {subsType === "monthly" && <IconButton disableRipple color="primary" sx={{ mt: "-30px" }}>
                                                                <Iconify icon="icon-park-solid:check-one" width={"16px"} />
                                                            </IconButton>}
                                                        </Stack>
                                                        <Stack direction="row" onClick={(e) => handleSubscriptionType(e, "annual", planId)} sx={{
                                                            border: subsType === "annual" ? "2px solid" : "1px solid",
                                                            borderColor: (theme) => subsType === "annual" ? theme.palette.primary.main : "grey.300",
                                                            borderRadius: "5px", p: 1, justifyContent: "space-between", "&:hover": { backgroundColor: "grey.300", cursor: "pointer" }
                                                        }}>
                                                            <Stack>
                                                                <Stack direction={"row"} gap={1}>
                                                                    <Typography>Annual</Typography>
                                                                    <Box>
                                                                        <Typography>{`Save ${discount}%`}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                                <Stack direction={"row"} gap={1}>
                                                                    <Typography>{formatPriceToRupees(priceAnnually)}</Typography>
                                                                    <Typography>/year/user</Typography>
                                                                </Stack>
                                                            </Stack>
                                                            {subsType === "annual" && <IconButton disableRipple color="primary" sx={{
                                                                mt: "-30px",
                                                            }}>
                                                                <Iconify icon="icon-park-solid:check-one" width={"16px"} height={"16px"} />
                                                            </IconButton>}
                                                        </Stack>
                                                    </Stack>

                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>))}


                            </Scrollbar>
                        </Stack>}

                        {step === "checkout" && <Stack gap={2}>
                            <Card sx={{ border: '1px solid', borderColor: 'grey.300', borderRadius: '6px !important', }}>
                                <BillingAddress billingAddress={billingAddress} handleBillingAddress={setBillingAddress} currentStep={currentStep} handleCurrentStep={setCurrentStep} />
                            </Card>
                            <Card sx={{ border: '1px solid', borderColor: 'grey.300', borderRadius: '6px !important', }}>
                                <Stack>
                                    {currentStep === "address" ? <Stack width={"100%"} justifyContent={"center"} p={"15px"}>
                                        <Typography variant="subtitle1">Payment Method</Typography>
                                    </Stack> :
                                        <Stack p={"20px"} gap={2}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                <Typography variant="subtitle1">Payment Method</Typography>
                                                {/* <Stack alignItems={"center"} direction={"row"} gap={1} p={"5px"} sx={{ backgroundColor: "grey.900", border: "1px solid", borderRadius: "5px" }}>
                                                <Iconify icon={"mingcute:wallet-fill"} />
                                                <Typography>{currentBalance}</Typography>
                                            </Stack> */}
                                            </Stack>
                                            <Stack gap={2}>
                                                {currentBalance > 0 && <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={payWallet}
                                                            onChange={(e) => setPayWallet(e.target.checked)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <Stack>
                                                            <Typography variant="body1">Sarv Pay balance</Typography>
                                                            <Typography varaint="caption" sx={{ fontSize: "14px" }}>{`Available balance : ${currentBalance}`}</Typography>
                                                        </Stack>
                                                    }
                                                />}
                                                <FormControl component="fieldset">
                                                    <RadioGroup value={payOnline} onChange={handlePayonline}>
                                                        <FormControlLabel value="online" control={<Radio />} label={<Typography varaint="body1"> Pay Online</Typography>} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack>
                                        </Stack>}
                                </Stack>
                            </Card>
                        </Stack>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={5} sx={{ widht: "100%", height: "95vh" }}>
                    <Stack direction={"column"} justifyContent={"space-between"} sx={{ backgroundColor: "grey.300", pt: "20px", pl: "40px", pr: "40px", pb: "80px", height: "100%", width: "100%", }}>
                        <Stack gap={3} sx={{ overflowY: "auto", height: "100%" }}>
                            <Typography variant="h4">Order Summary</Typography>
                            <Stack gap={1}>
                                <Stack gap={1}>
                                    <Typography>{selectedProducts.length} Item(s)</Typography>
                                    <Divider />
                                </Stack>
                                <Stack gap={2} sx={{ pt: "12px" }}>
                                    {selectedProducts.map(({ name, priceMonthly, priceAnnually, users, subsType, actualPrice }) => (
                                        <Stack key={name}>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Typography variant='h5'>{name}</Typography>
                                                <Typography variant='body1'>{formatPriceToRupees(actualPrice)}</Typography>
                                            </Stack>
                                            <Typography>{`${users} User(s)`}</Typography>
                                            <Typography>{subscriptionMapper?.[subsType]}</Typography>
                                        </Stack>
                                    ))}
                                    <Divider />
                                </Stack>
                                <Stack gap={2}>
                                    {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Typography>Subtotal</Typography>
                                        <Typography >₹41,682</Typography>
                                    </Stack> */}
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Typography variant='subtitle1'>Total</Typography>
                                        <Typography variant='subtitle1'>{formatPriceToRupees(totalPrice())}</Typography>
                                    </Stack>
                                    <Divider />
                                </Stack>
                                {totalSubsType?.totalMonthly > 0 && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ pt: "10px", pb: "10px" }}>
                                    <Stack>
                                        <Typography variant='subtitle1'>{"Total Billed Monthly"}</Typography>
                                        <Typography >{`Next charge date ${formatDate(31)}`}</Typography>
                                    </Stack>
                                    <Typography variant='subtitle1'>{formatPriceToRupees(totalSubsType?.totalMonthly)}</Typography>
                                </Stack>}
                                {totalSubsType?.totalAnnual > 0 && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ pt: "10px", pb: "10px" }}>
                                    <Stack>
                                        <Typography variant='subtitle1'>{"Total Billed Annually"}</Typography>
                                        <Typography >{`Next charge date ${formatDate(365)}`}</Typography>
                                    </Stack>
                                    <Typography variant='subtitle1'>{formatPriceToRupees(totalSubsType?.totalAnnual)}</Typography>
                                </Stack>}
                            </Stack>
                        </Stack>
                        <Stack gap={3}>
                            <Divider />
                            <Stack gap={2}>
                                <Stack gap={1}>
                                    <Stack key={"todayTotal"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Typography>Today's Total</Typography>
                                        <Typography>{formatPriceToRupees(totalPrice())}</Typography>
                                    </Stack>
                                    {payWallet && <Stack key={"wallet"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Typography>Sarv pay</Typography>
                                        <Typography sx={{ color: "#388e3c" }}>- {formatPriceToRupees(currentBalance > totalPrice() ? totalPrice() : currentBalance)}</Typography>
                                    </Stack>}
                                    <Stack gap={2}>
                                        <Divider sx={{
                                            borderStyle: 'dashed',
                                        }} />
                                        <Stack key={"online"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Typography variant="subtitle1">Amount To Pay</Typography>
                                            <Typography variant="subtitle1">{formatPriceToRupees((payWallet ? (totalPrice() - currentBalance >= 0 ? totalPrice() - currentBalance : 0) : totalPrice()))}</Typography>
                                        </Stack>
                                        <Divider sx={{
                                            borderStyle: 'dashed',
                                        }} />
                                    </Stack>
                                </Stack>
                                <LoadingButton disabled={step === "checkout" && currentStep !== "payment"} loading={loader} onClick={() => razorpayHandler()} variant="contained">Continue</LoadingButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </MainSection >
    )
}

export default Checkout;